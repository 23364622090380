<template>
  <section class="contactFormWrapper mx-3">
    <div class="container">
      <div class="row">
        <form action="#" id="contactUs" ref="contactUs" @submit.prevent>
          <div class="row">
            <div class="col-xs-12">
              <div class="textWrap">
                <h2>נציגי השטיח המעופף עומדים לרשותכם!</h2>
                <p>
                  התקשרו אלינו למוקד השירות, או שלחו לנו מייל, ואנו נחזור אליכם
                  בהקדם האפשרי.
                </p>
              </div>
            </div>
            <div class="col-sm-6 col-xs-12">
              <div class="inputWrap">
                <label for="firstName"><span>*</span>שם מלא</label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  required
                  role="textbox"
                />
              </div>
            </div>
            <div class="col-sm-6 col-xs-12">
              <div class="inputWrap">
                <label for="phone"><span>*</span>טלפון</label>
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  required
                  role="textbox"
                />
              </div>
            </div>
            <div class="col-sm-6 col-xs-12">
              <div class="inputWrap">
                <label for="email"><span>*</span>אימייל</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  role="textbox"
                />
              </div>
            </div>
            <div class="col-sm-6 col-xs-12">
              <div class="inputWrap">
                <label for="subject"><span>*</span>נושא הפנייה</label>
                <input
                  id="subject"
                  name="contact_subject"
                  type="text"
                  required
                  role="textbox"
                />
              </div>
            </div>
            <div class="col-xs-12 p-3">
              <div class="inputWrap">
                <label for="msgContent"><span>*</span>תוכן הפנייה</label>
                <textarea
                  name="contact_content"
                  id="msgContent"
                  cols="30"
                  rows="10"
                  required
                  style="resize:none;"
                ></textarea>
              </div>
              <div class="inputWrap checkWrap">
                <input
                  type="checkbox"
                  name="receiveNews"
                  id="mailAgree"
                  checked
                  aria-checked="false" ref="checkbox"
                />
                <span class="check">
                  <svg><use xlink:href="#i_check"></use></svg>
                </span>
                <label for="mailAgree" class="line"
                  >אני מאשר/ת קבלת הצעות, הטבות ומידע במייל/SMS. תוכל/י להסיר את
                  עצמך מהרשימה בכל עת.</label
                >
              </div>
              <div class="divider"></div>
            </div>
            <div class="col-sm-6 col-xs-12">
              <div
                class="g-recaptcha"
                data-sitekey="6LcvaBAUAAAAAB-geW2IyrWcENZ69euhYPzexHTR"
              >
                <div style="width: 304px; height: 78px;" class="mt-3">
                  <div>
                    <iframe
                      title="reCAPTCHA"
                      src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6LcvaBAUAAAAAB-geW2IyrWcENZ69euhYPzexHTR&amp;co=aHR0cHM6Ly93d3cuZmx5aW5nLmNvLmlsOjQ0Mw..&amp;hl=en&amp;v=UrRmT3mBwY326qQxUfVlHu1P&amp;size=normal&amp;cb=zgeg51r2n8a4"
                      width="304"
                      height="78"
                      role="presentation"
                      name="a-vwekjmfijuuq"
                      frameborder="0"
                      scrolling="no"
                      sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"
                    ></iframe>
                  </div>
                  <textarea
                    id="g-recaptcha-response"
                    name="g-recaptcha-response"
                    class="g-recaptcha-response"
                    style="width: 250px; height: 40px; border: 1px solid rgb(193, 193, 193); margin: 10px 25px; padding: 0px; resize: none; display: none;"
                    tabindex="-1"
                  ></textarea>
                </div>
                <iframe style="display: none;"></iframe>
              </div>
            </div>
            <div class="col-sm-6 col-xs-12">
              <div class="inputWrap">
                <button type="submit" role="button" @click="submit">
                  <span data-loading="עוד רגע :)">שלח</span>
                  {{ "\xa0" }}{{ "\xa0" }}{{ "\xa0" }}
                  <i class="fa fa-arrow-left"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      form: null,
    };
  },
  mounted() {
    this.form = this.$refs.contactUs;
  },
  methods: {
    async submit() {
      const info = {};
      if (!this.form.checkValidity()) return;
      for (let i = 0; i < this.form.elements.length; i += 1) {
        const e = this.form.elements[i];
        info[encodeURIComponent(e.name)] = encodeURIComponent(e.value);
      }
      info.receiveNews = this.$refs.checkbox.checked;
      const now = new Date();
      const offset = now.getTimezoneOffset();
      const date = new Date(now.getTime() - (offset * 60 * 1000)).toISOString().slice(0, 16).split('T')
        .join(' ');
      const body = {
        subject: decodeURIComponent(info.contact_subject),
        body: `
        <div dir="rtl" style="max-width: 100%;padding:20px;">
          <h1>${decodeURIComponent(info.contact_subject)}</h1>
          
          <p style="display:flex;"><span style="width: 10%;">${this.$t('booking.date')}:</span><span style="width: 80%;">${date}</span></p>
          <p style="display:flex;"><span style="width: 10%;">${this.$t('booking.name')}:</span><span style="width: 80%;">${decodeURIComponent(info.firstName)}</span></p>
          <p style="display:flex;"><span style="width: 10%;">${this.$t('booking.email')}:</span><span style="width: 80%;">${decodeURIComponent(info.email)}</span></p>
          <p style="display:flex;"><span style="width: 10%;">${this.$t('booking.phone')}:</span><span style="width: 80%;">${info.phone}</span></p>
          <p style="display:flex;"><span style="width: 10%;">${this.$t('footer.subject')}:</span><span style="width: 80%;">${decodeURIComponent(info.contact_subject)}</span></p>
          <p style="display:flex;"><span style="width: 10%;">${this.$t('footer.content')}:</span><span style="width: 80%;">${decodeURIComponent(info.contact_content)}</span></p>

          <p style="display:flex;"><span style="width: 10%;">${this.$t('footer.receive-news')}:</span><span style="width: 80%;">${info.receiveNews ? 'Yes' : 'No'}</span></p> 
          <!--<p style="display:flex;"><span style="width: 10%;">g-recaptcha-response:</span><span style="width: 80%;">${info['g-recaptcha-response']}</span></p>-->
        </div>`,
        contact: {
          FirstName: decodeURIComponent(info.firstName),
          PhoneNumber: info.phone,
          Email: decodeURIComponent(info.email),
        },
      };

      const message = await this.$store.dispatch('SEND_CONTACT_INFO_TO_ADMIN', body);
      const showMessage = message === 'Succeeded' ? this.$t('footer.contact-success-message') : this.$t('footer.contact-error-message');
      this.showMsgBox(showMessage);
    },
    showMsgBox(message) {
      this.boxTwo = '';
      this.$bvModal.msgBoxOk(message, {
        title: 'Confirmation',
        size: 'lg',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      })
        .then((value) => {
          this.boxTwo = value;
        });
    },
  },
};
</script>
<style scoped>
.contactFormWrapper {
  padding: 50px 0;
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.contactFormWrapper .textWrap {
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.contactFormWrapper .textWrap h2 {
  color: #003656;
  font-size: 1.25rem;
  font-weight: 500;
}
.contactFormWrapper .textWrap p {
  color: #5f5f5f;
  font-size: 1.125rem;
  font-weight: 500;
  margin: 0;
}
.contactFormWrapper
  .inputWrap
  input:not([type="checkbox"]):not([type="range"]) {
  border-color: #0a85b1;
  color: #343434;
}
.inputWrap input:not([type="checkbox"]):not([type="range"]) {
  width: 100% !important;
  padding-right: 15px;
  padding-left: 15px;
}
.inputWrap input:not([type="checkbox"]):not([type="range"]) {
  width: 100%;
  height: 49px;
  border-radius: 25px;
  border: 1px solid #fff;
  padding: 0 15px;
  color: #fff;
  -webkit-appearance: none;
}
.contactFormWrapper .inputWrap textarea {
  width: 100%;
  height: 149px;
  border-radius: 25px;
  border: 1px solid #0a85b1;
  color: #343434;
  padding: 15px;
}
.contactFormWrapper .inputWrap {
  margin-bottom: 20px;
}
.contactFormWrapper button[type="submit"] {
  width: 300px;
  height: 50px;
  border-radius: 25px;
  background: #10c386;
  border: 2px solid #10c386;
  color: #fff;
  font-size: 1.125rem;
  margin: 30px 0;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}
.contactFormWrapper button[type="submit"]:hover,
.contactFormWrapper button[type="submit"]:focus {
  background: #fff;
  color: #10c386;
  transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}
.contactFormWrapper .inputWrap {
  margin-bottom: 20px;
}
.inputWrap {
  position: relative;
}
.contactFormWrapper .inputWrap input[type="checkbox"] {
  width: 24px;
  height: 24px;
}
.inputWrap input[type="checkbox"] {
  width: 17px;
  height: 17px;
  right: 0;
  top: 0;
  position: absolute;
  /* opacity: 0; */
  margin: 0;
  z-index: 1;
}
.contactFormWrapper .check {
  border: 1px solid #1dc68d;
  width: 24px;
  height: 24px;
}
.check {
  width: 17px;
  height: 17px;
  display: inline-block;
  border: 1px solid #fff;
  text-align: center;
  vertical-align: top;
  margin-left: 5px;
}
.contactFormWrapper .inputWrap label {
  color: #343434;
  font-size: 1.125rem;
  max-width: calc(100% - 35px);
}
.inputWrap label {
  font-weight: 400;
  color: #fff;
  font-size: 0.875rem;
}
.divider {
  width: 100%;
  height: 1px;
  background: #bfbfbf;
}
.col-xs-12 {
  width: 100%;
}
</style>
